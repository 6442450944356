import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDeleteRequest,
  fetchGetRequest,
  fetchPatchRequest,
  fetchPostRequest,
} from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { deleteModalHide } from "./modals";

const initialState = {
  isLoading: false,
  isPopupLoading: false,
  restaurantCategories: null,
  editRestaurantCategories: null,
};

export const getRestaurantCategoryForAdmin = createAsyncThunk(
  "restaurantCategory/getRestaurantFoodCategoryForAdmin",
  async ({ searchQuery }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getRestaurantFoodCategoryForAdmin,
        {
          params: {
            page: 1 || undefined,
            size: 999999 || undefined,
            keywords: searchQuery || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      // toast.error(err?.data?.message || "Failed to get Categories");
      console.log("get category err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getRestaurantCategoryForAdminById = createAsyncThunk(
  "restaurantCategory/getRestaurantCategoryForAdminById",
  async ({ restaurant_category_id }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getRestaurantCategoryForAdminById(
          restaurant_category_id
        )
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("get category err", JSON.stringify(err, null, 4));
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const createRestaurantCategoryforAdmin = createAsyncThunk(
  "restaurantCategory/createRestaurantCategoryforAdmin",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.createRestaurantCategoryforAdmin,
        payload
      );
      toast.success(
        response?.data?.message || "Restaurant Category created successfully"
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log(
        "create restaurant category err",
        JSON.stringify(err, null, 4)
      );
      toast.error(err?.data?.message || "Failed to create Restaurant Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const editRestaurantCategoryforAdmin = createAsyncThunk(
  "restaurantCategory/editRestaurantCategoryforAdmin",
  async ({ payload, restaurant_category_id }, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.editRestaurantCategoryforAdmin(
          restaurant_category_id
        ),
        payload
      );
      toast.success(
        response?.data?.message || "Restaurant Category updated successfully"
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("edit restaurant category err", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to update Restaurant Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const deleteRestaurantCategoryForAdmin = createAsyncThunk(
  "restaurantCategory/deleteRestaurantCategoryForAdmin",
  async ({ restaurant_category_id }, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(
        endpoints.dashboard.deleteRestaurantCategoryForAdmin(
          restaurant_category_id
        )
      );
      toast.success(
        response?.data?.message || "Restaurant Category deleted Successfully"
      );
      thunkAPI.dispatch(deleteModalHide());
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      thunkAPI.dispatch(deleteModalHide());
      console.log("restaurant category", JSON.stringify(err, null, 4));
      toast.error(err?.data?.message || "Failed to delete Restaurant Category");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "restaurantCategory",
  initialState,
  reducers: {
    setGetRestaurantCategoryForAdminIsLOadingToTrue: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) =>
    builder

      .addCase(getRestaurantCategoryForAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurantCategoryForAdmin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restaurantCategories = action?.payload?.restaurantCategories;
      })
      .addCase(getRestaurantCategoryForAdmin.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(getRestaurantCategoryForAdminById.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getRestaurantCategoryForAdminById.fulfilled, (state, action) => {
        state.isLoading = false;
        state.editRestaurantCategories = action?.payload?.restaurantCategory;
      })
      .addCase(getRestaurantCategoryForAdminById.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(createRestaurantCategoryforAdmin.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(createRestaurantCategoryforAdmin.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(createRestaurantCategoryforAdmin.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(editRestaurantCategoryforAdmin.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(editRestaurantCategoryforAdmin.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(editRestaurantCategoryforAdmin.rejected, (state) => {
        state.isPopupLoading = false;
      })

      .addCase(deleteRestaurantCategoryForAdmin.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(deleteRestaurantCategoryForAdmin.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(deleteRestaurantCategoryForAdmin.rejected, (state) => {
        state.isPopupLoading = false;
      }),
});

export default reducer;
export const { setGetRestaurantCategoryForAdminIsLOadingToTrue } = actions;
