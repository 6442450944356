import React, { useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import "./deal.scss";
import {
  dealsData,
  deleteAdminDeal,
  deleteDeal,
  getAdminDealById,
  getDeal,
  getDealAdmin,
  getDealById,
} from "../../../../store/reducers/deal";
import { useDispatch, useSelector } from "react-redux";
import DeletePopup from "../../../components/delete-popup";
import toast from "react-hot-toast";
import { constant } from "../../../constants";
import DeleteIcon from "../../../../assets/images/del-icon.svg";
import Loading from "../../../components/loading";
import {
  selectDealData,
  selectRestaurantLocationData,
} from "../../../../store/selectors/dashboardSelectors";
import { selectIsDeleteModalOpen } from "../../../../store/selectors/modalSelectors";
import {
  deleteModalHide,
  deleteModalOpen,
  editDealModalHide,
  successModalHide,
} from "../../../../store/reducers/modals";
import {
  selectRestaurantDealId,
  userData,
} from "../../../../store/selectors/authSelectors";
import { getRestaurantAllAddress } from "../../../../store/reducers/restaurantAddress";

const EditDealPopup = ({ onClose, restaurantDealId, pageNum, pageSize }) => {
  const dealData = useSelector(selectDealData);
  const restaurantDealIdData = useSelector(selectRestaurantDealId);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const [selectedRestaurantDealId, setSelectedRestaurantDealId] =
    useState(null);
  const [isFoodDeals, setIsFoodDeals] = useState(null);
  const [isChecked, setIsChecked] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isActiveChecked, setIsActiveChecked] = useState("");
  const formikRef = useRef();
  const idToFetch = restaurantDealId || restaurantDealIdData;
  const user = useSelector(userData);
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantId = restaurantDetailData?.restaurantId;
  const [addresses, setAddresses] = useState([]);

  const restaurantLocationId = restaurantDetailData?.restaurantId;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = format(new Date(), "yyyy-MM-dd");
  const id = restaurantDetailData?.restaurantDetails?.restaurant?.restaurantId;

  useEffect(() => {
    setIsLoading(true);
    dispatch(
      getRestaurantAllAddress({
        restaurantId: restaurantLocationId,
        page: 1,
        size: 9999999999,
      })
    ).then((response) => {
      if (response?.meta?.requestStatus === constant.thunkStatus.FULFILLED) {
        setAddresses(response?.payload?.addresses ?? []);
        setIsLoading(false);
      }
    });
  }, [restaurantLocationId]);

  useEffect(() => {
    const setDealFields = (deal) => {
      formikRef?.current?.setFieldValue(
        "endDate",
        deal?.endDate ? format(new Date(deal?.endDate), "yyyy-MM-dd") : ""
      );
      formikRef?.current?.setFieldValue(
        "location",
        deal?.foodDeals?.[0].restaurantAddressId
      );
      formikRef?.current?.setFieldValue(
        "startDate",
        format(new Date(deal?.startDate), "yyyy-MM-dd")
      );
      formikRef?.current?.setFieldValue("dealTitle", deal?.restaurantDealTitle);
      formikRef?.current?.setFieldValue("claimPerUser", deal?.claimPerUser);
      formikRef?.current?.setFieldValue("isActive", deal?.isActive);
      setIsFoodDeals(deal?.foodDeals);
      setSelectedRestaurantDealId(idToFetch);
      setIsChecked(deal?.claimPerUser === 1 ? "two" : "one");
      setIsActiveChecked(deal?.isActive);
    };

    const fetchDeal = (fetchFunction) => {
      fetchFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            const deal = response?.payload || {};
            if (deal) {
              setDealFields(deal);
            }
            break;

          default:
            break;
        }
      });
    };

    if (user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin) {
      fetchDeal(() => dispatch(getDealById(idToFetch)));
    } else {
      fetchDeal(() => dispatch(getAdminDealById({ idToFetch })));
    }
  }, [
    dispatch,
    idToFetch,
    restaurantId,
    user.userSettings?.isSuperAdmin,
    user.userType,
  ]);

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.id);
  };

  const handleToggle = () => {
    setIsActiveChecked(!isActiveChecked);
  };

  const handleEditDeal = async (values) => {
    let dealData = {
      ...values,
      selectedRestaurantDealId: selectedRestaurantDealId,
      isFoodDeals: isFoodDeals,
    };
    if (isChecked === "two") {
      dealData.claimPerUser = 1;
    } else if (isChecked === "one") {
      delete dealData.claimPerUser;
    } else {
      toast.error("Please select a claim count per user option.");
      return;
    }
    dealData.isActive = isActiveChecked ? true : false;
    dispatch(dealsData(dealData));
    navigate("/dashboard/select-related-menu-items", {
      state: { isEdit: true, restaurantAddressId: values.location },
    });
    if (restaurantDealIdData) {
      dispatch(editDealModalHide());
      dispatch(successModalHide());
    }
  };

  const handleDeleteClick = () => {
    setSelectedRestaurantDealId(idToFetch);
    dispatch(deleteModalOpen());
  };

  const handledeleteDeal = async (restaurantDealId) => {
    const deleteDealFunction =
      user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
        ? () => dispatch(deleteDeal(restaurantDealId))
        : () => dispatch(deleteAdminDeal(restaurantDealId));
    await deleteDealFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          if (
            user.userType === "Restaurant" &&
            !user.userSettings?.isSuperAdmin
          ) {
            dispatch(getDeal({ pageNum: pageNum, pageSize: pageSize }));
            navigate("/dashboard/deals");
          } else {
            dispatch(
              getDealAdmin({ adminDealId: restaurantId, pageNum, pageSize })
            );
            navigate(`/dashboard/restaurant-location-owner/view-detail/${id}`, {
              state: { activeTab: "deal-tab" },
            });
          }
          onClose();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="popup">
      <div className="popup-content2">
        <h2>Edit a Deal</h2>
        <div>
          {dealData?.isLoading ||
            (isLoading && (
              <div className="tableLoader">
                <div
                  style={{ borderRadius: "30px", top: "0" }}
                  className="spinnerLoad"
                >
                  <Loading></Loading>
                </div>
              </div>
            ))}
          <Formik
            innerRef={formikRef}
            initialValues={{
              location: "",
              dealTitle: "",
              startDate: "",
              endDate: "",
            }}
            validationSchema={Yup.object().shape({
              location: Yup.string().required("Location is required"),
              dealTitle: Yup.string()
                .required("Deal Title is required")
                .max(150, "Deal Title cannot exceed 150 characters"),
              startDate: Yup.date().required("Start Date is required"),
              endDate: Yup.date().min(
                Yup.ref("startDate"),
                "End Date cannot be earlier than Start Date"
              ),
            })}
            onSubmit={(values) => handleEditDeal(values)}
          >
            {({ isSubmitting, isValid }) => {
              return (
                <Form>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="location">Location*</label>
                        <Field
                          as="select"
                          name="location"
                          id="location"
                          className="form-control"
                        >
                          <option value="" disabled>
                            Select a location
                          </option>
                          {addresses?.map((address) => (
                            <option
                              key={address?.id}
                              value={address?.addressId}
                            >
                              {address?.streetAddress}
                            </option>
                          ))}
                        </Field>
                        <ErrorMessage
                          name="location"
                          component="div"
                          className="deal-error-message"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="dealTitle">Deal Title*</label>
                        <Field
                          type="text"
                          name="dealTitle"
                          id="dealTitle"
                          placeholder="Deal Title"
                          component={Input}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="startDate">Start Date*</label>
                        <Field
                          type="date"
                          name="startDate"
                          id="startDate"
                          onFocus={(e) => e.target.showPicker()}
                          component={Input}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="endDate">End Date</label>
                        <Field
                          type="date"
                          name="endDate"
                          id="endDate"
                          min={today}
                          onFocus={(e) => e.target.showPicker()}
                          component={Input}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label>Claim Count Per User</label>
                        <div className="claimCheckRow">
                          <div className="claimCheckSection">
                            <input
                              id="one"
                              type="checkbox"
                              checked={isChecked === "one"}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="one">Unlimited Use</label>
                          </div>
                          <div className="claimCheckSection">
                            <input
                              id="two"
                              type="checkbox"
                              checked={isChecked === "two"}
                              onChange={handleCheckboxChange}
                            />
                            <label htmlFor="two">One-Time Use</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <div className="claimCheckRow">
                          <div className="claimCheckSection">
                            <input
                              id="isActive"
                              type="checkbox"
                              onChange={handleToggle}
                              checked={isActiveChecked}
                            />
                            <label htmlFor="isActive">isActive</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="flxendBtn">
                    <img
                      style={{ cursor: "pointer" }}
                      onClick={handleDeleteClick}
                      src={DeleteIcon}
                      alt="Logo"
                    />
                    <Button
                      type="button"
                      className="dealCancleBtn"
                      onClick={onClose}
                    >
                      Cancel
                    </Button>
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="dealbtn"
                    >
                      Edit Related Menu Items
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => handledeleteDeal(selectedRestaurantDealId)}
          popuploading={dealData?.isPopupLoading}
          title="Delete Deal"
          description="Are you sure you want to delete this deal? The deal data will be erased and this process is irreversible."
          buttonName="Delete"
        />
      )}
    </div>
  );
};

export default EditDealPopup;
