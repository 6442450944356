import React, { useState, useEffect } from "react";
import { toast } from "react-hot-toast";
import Layout from "../../../layouts/dashboard-layout";
import Loading from "../../../components/loading";
import "./restaurant.scss";
import DeletePopup from "../../../components/delete-popup";
import { useDispatch, useSelector } from "react-redux";
import {
  getRestaurant,
  removeAccessRestaurant,
  setGetRestaurantIsLoadingToTrue,
} from "../../../../store/reducers/restaurant";
import { selectRestaurantData } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import CreateRestaurantPopup from "./createRestaurantPopup";
import {
  selectIsCreateRestaurantModalOpen,
  selectIsDeleteModalOpen,
} from "../../../../store/selectors/modalSelectors";
import {
  createRestaurantModalHide,
  createRestaurantModalOpen,
  deleteModalHide,
  deleteModalOpen,
} from "../../../../store/reducers/modals";
import { useNavigate } from "react-router-dom";
import { restaurantId } from "../../../../store/reducers/restaurantLocationDetail";

const Restaurant = () => {
  const restaurantData = useSelector(selectRestaurantData);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const isPopupOpen = useSelector(selectIsCreateRestaurantModalOpen);
  const [restaurant, setRestaurants] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("restaurantName");
  const [selectedRestaurantId, setSelectedRestaurantId] = useState(null);
  const [selectedAccountStatus, setSelectedAccountStatus] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRestauarntLocationNavigation = async (id) => {
    dispatch(restaurantId(id));
    navigate(`/dashboard/restaurant-location-owner/view-detail/${id}`, {
      state: { referrer: "/dashboard/restaurant" },
    });
  };

  const getRestaurants = async () => {
    await dispatch(
      getRestaurant({
        searchQuery,
        order_by: sortBy,
        order_type: sortDirection,
      })
    ).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setRestaurants(response?.payload?.restaurants);
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
  };

  useEffect(() => {
    dispatch(setGetRestaurantIsLoadingToTrue());

    const handler = setTimeout(() => {
      getRestaurants();
    }, 500);

    dispatch(createRestaurantModalHide());
    dispatch(deleteModalHide());
    return () => {
      dispatch(createRestaurantModalHide());
      dispatch(deleteModalHide());
      clearTimeout(handler);
    };
  }, [dispatch, searchQuery, sortBy, sortDirection]);

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleRemoveAccess = (restaurant_id, accountStatus) => {
    setSelectedRestaurantId(restaurant_id);
    setSelectedAccountStatus(accountStatus);
    dispatch(deleteModalOpen());
  };

  const removeAccess = async (restaurant_id, accountStatus) => {
    const payload = {
      account_status: accountStatus === "Approved" ? "Suspended" : "Approved",
    };

    dispatch(removeAccessRestaurant({ payload, restaurant_id })).then(
      (response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            toast.success(
              response?.payload?.message ||
                (accountStatus === "Approved"
                  ? "Restaurant Suspended successfully"
                  : "Restaurant Approved successfully")
            );
            getRestaurants();
            return;

          default:
            return;
        }
      }
    );
  };

  const handleSort = (criterion) => {
    setSortBy(criterion);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const getLocationText = (addresses) => {
    if (!addresses || addresses?.length === 0) {
      return "N/A";
    } else if (addresses?.length === 1) {
      return addresses[0]?.streetAddress;
    } else {
      return `${addresses[0]?.streetAddress} +${addresses?.length - 1} more`;
    }
  };

  const handleLocationHover = (addresses) => {
    if (addresses && addresses?.length > 1) {
      const locations = addresses
        .map((address) => address?.streetAddress)
        .join("; ");
      return locations;
    }
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Restaurants</h2>
          <div className="iconSce">
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div>
              <i
                onClick={() => dispatch(createRestaurantModalOpen())}
                className="bx bx-plus"
              ></i>
            </div>
          </div>
        </div>

        <div style={{ position: "relative" }} className="restaurantTtableSec">
          {restaurantData?.isLoading && (
            <div className="tableLoader">
              <div className="spinnerLoad2fix">
                <Loading></Loading>
              </div>
            </div>
          )}
          {isDataLoaded && (
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    Restaurant Name{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("restaurantName")}
                    />
                  </th>
                  <th scope="col">Restaurant Category </th>
                  <th scope="col">
                    Restaurant Email{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("emailAddress")}
                    />
                  </th>
                  <th scope="col">Location </th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {restaurant?.length <= 0 && (
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan="4">
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          padding: "50px 0 0",
                        }}
                      >
                        No Data Found
                      </div>
                    </td>
                  </tr>
                )}
                {restaurant?.map((restaurant) => (
                  <tr
                    key={restaurant?.restaurant?.restaurantId}
                    onClick={() => {
                      handleRestauarntLocationNavigation(
                        restaurant?.restaurant?.restaurantId
                      );
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    <td data-label="Restaurant Name">
                      {restaurant?.restaurant?.restaurantName}
                    </td>
                    <td data-label="Restaurant Category">
                      {restaurant?.restaurant?.restaurantCategories?.length > 0
                        ? restaurant?.restaurant?.restaurantCategories
                            .map((category) => category.restaurantCategoryName)
                            .join(", ")
                        : "N/A"}
                    </td>
                    <td data-label="Restaurant Email">
                      {restaurant?.emailAddress}
                    </td>
                    <td data-label="Location">
                      {restaurant?.restaurant?.restaurantAddressCount}
                    </td>
                    <td className="textEnd" data-label="Actions">
                      <div
                        className={
                          restaurant?.accountStatus === "Approved"
                            ? "raBtn"
                            : "raBtn greenBtn"
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveAccess(
                            restaurant?.restaurant?.restaurantId,
                            restaurant?.accountStatus
                          );
                        }}
                      >
                        {restaurant?.accountStatus === "Approved"
                          ? "Remove Access"
                          : "Approve Access"}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isPopupOpen && (
        <CreateRestaurantPopup
          closePopup={() => dispatch(createRestaurantModalHide())}
          getRestaurants={getRestaurants}
          restaurantData={restaurantData}
        />
      )}
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() =>
            removeAccess(selectedRestaurantId, selectedAccountStatus)
          }
          popuploading={restaurantData?.isPopupLoading}
          title={
            selectedAccountStatus === "Approved"
              ? "Remove Access"
              : "Approve Access"
          }
          description={
            selectedAccountStatus === "Approved"
              ? "Are you sure you want to remove this restaurant's access? They won't be able to manage their restaurant anymore."
              : "Are you sure you want to approve this restaurant's access? They will now be able to manage their restaurant."
          }
          buttonName={
            selectedAccountStatus === "Approved"
              ? "Remove Access"
              : "Approve Access"
          }
        />
      )}
    </Layout>
  );
};

export default Restaurant;
