import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchDeleteRequest,
  fetchPatchRequest,
  fetchPostRequest,
} from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";
import { logoutUser } from "./authSlice";

const initialState = {
  isLoading: false,
  isResendLoading: false,
};

export const login = createAsyncThunk(
  "authAdmin/login",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(endpoints.auth.login, payload);

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to Login");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  "authAdmin/forgotPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.auth.forgotPassword,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to send reset email");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const resetPassword = createAsyncThunk(
  "authAdmin/resetPassword",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.auth.resetPassword,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message || "Failed to Reset Password");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const changePassword = createAsyncThunk(
  "authAdmin/changePassword",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.auth.changePassword,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      toast.error(err?.data?.message || "Failed to change password");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const logout = createAsyncThunk(
  "authAdmin/logout",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchDeleteRequest(endpoints.auth.logout);
      toast.success(response?.data?.message);
      thunkAPI.dispatch(logoutUser());
      localStorage.clear();
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "authAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(changePassword.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(login.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(forgotPassword.pending, (state) => {
        state.isResendLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isResendLoading = false;
      })
      .addCase(forgotPassword.rejected, (state) => {
        state.isResendLoading = false;
      })

      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(resetPassword.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(logout.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
      }),
});

export default reducer;
export const {} = actions;
