import React from "react";
import Button from "../../../components/button";
import Logo from "../../../../assets/images/logo.png";
import "./welcome.scss";
import { useDispatch } from "react-redux";
import { loginType } from "../../../../store/reducers/authSlice";
import { useNavigate } from "react-router-dom";

const Welcome = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleButtonClick = ({ userTypeValue, userTypeLabel }) => {
    navigate(`/login`, { state: { userType: userTypeValue } });
  };

  return (
    <div className="welcome-wrapper">
      <div className="welcome-row">
        <div className="left-section">
          <img src={Logo} alt="Logo" />
        </div>
        <div className="right-section">
          <div className="welcome-container">
            <h2>Welcome!</h2>
            <p>Are you logging in as Admin or Restaurant?</p>
            <Button
              type="button"
              className="welcome-button"
              onClick={() =>
                handleButtonClick({
                  userTypeValue: "Restaurant",
                  userTypeLabel: "Restaurant",
                })
              }
            >
              Restaurant
            </Button>
            <Button
              type="button"
              className="welcome-button"
              onClick={() =>
                handleButtonClick({
                  userTypeValue: "Administrator",
                  userTypeLabel: "Admin",
                })
              }
            >
              Admin
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
