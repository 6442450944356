import axios from "axios";
import { constant } from "../app/constants";
import { logout } from "../store/reducers/auth";
import { logoutUser } from "../store/reducers/authSlice";

const http = axios.create({
  baseURL: constant.BASE_URL,
});

export const setupInterceptor = async (store) => {
  http.interceptors.request.use(
    async (config) => {
      const token = store?.getState()?.auth?.token;

      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  http.interceptors.response.use(
    (response) => {
      return response;
    },

    async (error) => {
      if (error.response.status === 401) {
        store.dispatch(logoutUser());
        localStorage.clear();
      }
      // Return any other errors (except 401)
      return Promise.reject(error);
    }
  );
};
export const fetchGetRequest = (endpoint, extraConfig = {}) => {
  return http.get(endpoint, { ...extraConfig });
};

export const fetchPostRequest = (endpoint, payload, extraConfig = {}) => {
  return http.post(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};

export const fetchMultipartPostRequest = (
  endpoint,
  payload,
  extraConfig = {}
) => {
  return http
    .post(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    })
    .catch((error) => {
      return Promise.reject(error.response);
    });
};

export const fetchPutRequest = (endpoint, payload, extraConfig = {}) => {
  return http.put(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};

export const fetchMultipartPutRequest = async (
  endpoint,
  payload,
  extraConfig = {}
) => {
  try {
    return await http.put(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const fetchPatchRequest = (endpoint, payload, extraConfig = {}) => {
  return http.patch(endpoint, payload, { ...extraConfig });
};

export const fetchMultipartPatchRequest = async (
  endpoint,
  payload,
  extraConfig = {}
) => {
  try {
    return await http.patch(endpoint, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      ...extraConfig,
    });
  } catch (error) {
    return Promise.reject(error.response);
  }
};

export const fetchDeleteRequest = (endpoint, payload, extraConfig = {}) => {
  return http.delete(endpoint, payload, { ...extraConfig }).catch((error) => {
    return Promise.reject(error.response);
  });
};
