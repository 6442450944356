import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import { toast } from "react-hot-toast";
import Loading from "../../../components/loading";
import "./admin.scss";
import { useDispatch } from "react-redux";
import { createAdmin } from "../../../../store/reducers/admin";
import { constant } from "../../../constants";
import PhoneInput from "react-phone-input-2";
import { validatePhoneNumber } from "../../../components/formatAndValidate";

const CreateAdminPopup = ({ closePopup, getAdmins, adminData }) => {
  const dispatch = useDispatch();

  const handleCreateAdmin = async (values) => {
    const payload = {
      firstName: values.firstName?.trim(),
      lastName: values.lastName?.trim(),
      emailAddress: values?.email?.toLowerCase()?.trim(),
      password: values?.password?.trim(),
      phoneCountryCode: values?.phoneCountryCode?.trim(),
      phoneNumber: values?.phoneNumber
        ?.slice(values?.phoneCountryCode?.length)
        ?.trim(),
    };

    dispatch(createAdmin(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message || "Admin created successfully"
          );
          closePopup();
          getAdmins();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Add Admin Account</h2>
        <i onClick={closePopup} className="bx bx-x"></i>
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phoneNumber: "",
            phoneCountryCode: "",
          }}
          validationSchema={Yup.object().shape({
            firstName: Yup.string()
              .required("Admin's First Name is required")
              .max(50, "Admin's First Name cannot exceed 50 characters"),
            lastName: Yup.string()
              .required("Admin's Last Name is required")
              .max(50, "Admin's Last Name cannot exceed 50 characters"),
            email: Yup.string()
              .email("Invalid email")
              .required("Email Address is required")
              .trim(),
            password: Yup.string()
              .required("Password is required")
              .matches(
                /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z\d!@#$%^&*]{8,}$/,
                "Password must contain at least 8 characters, including one uppercase letter, one lowercase letter, and one number,  and one special character (!@#$%^&*)"
              ),
            phoneNumber: Yup.string()
              .required("Phone Number is required")
              .test(
                "is-valid-phone",
                "Phone number is not valid",
                function (value) {
                  const countryCode = this.parent.phoneCountryCode;
                  return validatePhoneNumber(value, countryCode);
                }
              ),
          })}
          onSubmit={(values, { setSubmitting }) =>
            handleCreateAdmin(values, setSubmitting)
          }
        >
          {({ isSubmitting, isValid, values, setFieldValue }) => {
            return (
              <Form>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="firstName">Admin's First Name</label>
                      <Field
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="Admin's First Name"
                        component={Input}
                      />
                    </div>
                  </div>
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="lastName">Admin's Last Name</label>
                      <Field
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Admin's Last Name"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email@example.com"
                        component={Input}
                      />
                    </div>
                  </div>
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="phoneNumber">Phone Number</label>
                      <PhoneInput
                        country={"us"}
                        value={values.phoneNumber}
                        onlyCountries={["us"]}
                        disableDropdown={true}
                        onChange={(phone, data) => {
                          setFieldValue("phoneCountryCode", data?.dialCode);
                          setFieldValue("phoneNumber", phone);
                        }}
                      />
                      <div className="error-text">
                        <ErrorMessage name={`phoneNumber`} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flexRow">
                  <div className="formCol">
                    <div className="form-group">
                      <label htmlFor="password">Create Password</label>
                      <Field
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Password"
                        component={Input}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className="btn"
                >
                  {adminData?.isPopupLoading && <Loading></Loading>}
                  {isSubmitting ? "Submitting..." : "Create"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};
export default CreateAdminPopup;
