import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest, fetchPostRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";

const initialState = {
  isSendingNotification: false,
  isFethingNotification: false,
  notificationsData: [],
};

export const sendNotification = createAsyncThunk(
  "notification/sendNotification",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPostRequest(
        endpoints.dashboard.sendNotification,
        payload
      );
      toast.success(
        response?.data?.message || "Notification sent successfully"
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getNotificationsData = createAsyncThunk(
  "notification/getNotificationsData",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getNotifications,
        {
          params: {
            page: payload?.page,
            size: payload?.size,
            keywords: payload?.keywords,
          },
        }
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendNotification.pending, (state) => {
        state.isSendingNotification = true;
      })
      .addCase(sendNotification.fulfilled, (state) => {
        state.isSendingNotification = false;
      })
      .addCase(sendNotification.rejected, (state) => {
        state.isSendingNotification = false;
      })
      .addCase(getNotificationsData.pending, (state) => {
        state.isFethingNotification = true;
      })
      .addCase(getNotificationsData.fulfilled, (state, action) => {
        state.isFethingNotification = false;
        state.notificationsData = action?.payload?.admin_notifications;
      })
      .addCase(getNotificationsData.rejected, (state) => {
        state.isFethingNotification = false;
      });
  },
});

export default reducer;
