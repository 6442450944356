import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";

const initialState = {
  isAppUsersLoading: false,
  appUsersData: [],
};

export const getAppUsersData = createAsyncThunk(
  "user/getAppUsersData",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchGetRequest(endpoints.dashboard.getUsers, {
        params: {
          page: 1,
          size: 9999999999,
        },
      });

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log(err);
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAppUsersData.pending, (state) => {
        state.isAppUsersLoading = true;
      })
      .addCase(getAppUsersData.fulfilled, (state, action) => {
        state.isAppUsersLoading = false;
        state.appUsersData = action?.payload?.users;
      })
      .addCase(getAppUsersData.rejected, (state) => {
        state.isAppUsersLoading = false;
      });
  },
});

export default reducer;
