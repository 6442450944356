import React from "react";
import "../restaurant-locations.scss";
import { Formik, Form, ErrorMessage } from "formik";
import Button from "../../../../components/button";
import * as Yup from "yup";
import ImageUploader from "./imageUploader";
import GoogleAutoComplete from "../../../../components/google-auto-complete";
import PhoneInput from "react-phone-input-2";
import Loading from "../../../../components/loading";
import DeletePopup from "../../../../components/delete-popup";
import {
  deleteModalHide,
  deleteModalOpen,
} from "../../../../../store/reducers/modals";
import { useDispatch } from "react-redux";
import { validatePhoneNumber } from "../../../../components/formatAndValidate";
import { formatAddress } from "../../../../../utils/formatter";

function EditRestaurantLocation({
  closePopup,
  isDeletePopup,
  selectedRestaurantId,
  setSelectedRestaurantId,
  handleEditRestaurantLocation,
  editRestaurantLocationData,
  popuploading,
  handleRemoveAccess,
}) {
  const dispatch = useDispatch();
  const deleteRestaurantLocation = () => {
    if (selectedRestaurantId) {
      handleRemoveAccess(selectedRestaurantId);
    }
  };

  const initialValues = {
    location: formatAddress(
      editRestaurantLocationData?.streetAddress,
      editRestaurantLocationData?.city,
      editRestaurantLocationData?.state,
      editRestaurantLocationData?.zipCode
        ? `- ${editRestaurantLocationData.zipCode}`
        : undefined,
      editRestaurantLocationData?.country
    ),
    phoneNumber:
      editRestaurantLocationData?.phoneCountryCode +
      editRestaurantLocationData?.phoneNumber,
    phoneCountryCode: editRestaurantLocationData?.phoneCountryCode,
  };
  const validationSchema = Yup.object().shape({
    location: Yup.mixed().required("Location is required"),
    phoneNumber: Yup.string()
      .required("Phone Number is required")
      .test("is-valid-phone", "Phone number is not valid", function (value) {
        const countryCode = this.parent.phoneCountryCode;
        return validatePhoneNumber(value, countryCode);
      }),
  });
  const handleSubmitData = (values) => {
    handleEditRestaurantLocation(values);
  };
  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Edit Restaurant Location</h2>
        <i onClick={closePopup} className="bx bx-x popClose"></i>
        <div>
          {!editRestaurantLocationData && (
            <div className="tableLoader">
              <div
                style={{ borderRadius: "30px", top: "0" }}
                className="spinnerLoad"
              >
                <Loading></Loading>
              </div>
            </div>
          )}
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={(values) => handleSubmitData(values)}
          >
            {({ isSubmitting, isValid, setFieldValue, values }) => {
              console.log("values", values);
              return (
                <Form>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="location">Location</label>
                        <GoogleAutoComplete
                          onInputEmpty={() => setFieldValue("location", "")}
                          onSelect={(place, address) =>
                            setFieldValue("location", place)
                          }
                          searchQuery={values.location}
                        />
                        <div className="error-text">
                          <ErrorMessage name={`location`} />
                        </div>
                      </div>
                    </div>
                    <div className="formCol">
                      <div className="form-group">
                        <label htmlFor="phoneNumber">Phone Number</label>
                        <PhoneInput
                          country={"us"}
                          value={values.phoneNumber}
                          onlyCountries={["us"]}
                          disableDropdown={true}
                          onChange={(phone, data) => {
                            setFieldValue("phoneCountryCode", data?.dialCode);
                            setFieldValue("phoneNumber", phone);
                          }}
                        />
                        <div className="error-text">
                          <ErrorMessage name={`phoneNumber`} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flexRow">
                    <div className="formCol fullcolWidth100">
                      <div className="form-group imgUploaders">
                        <label style={{ marginBottom: "10px" }}>
                          Location Specific Photos
                        </label>
                        <ImageUploader
                          companyWideImages={
                            editRestaurantLocationData?.locationSpecificImages
                          }
                          handleImage={(image) =>
                            setFieldValue("images", image)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flxendrightBtn resUploaedBtns">
                    <a
                      className="popdealCancleBtn mb0"
                      onClick={() => {
                        setSelectedRestaurantId(
                          editRestaurantLocationData?.addressId
                        );
                        dispatch(deleteModalOpen());
                      }}
                    >
                      Delete
                    </a>
                    <Button
                      type="submit"
                      disabled={isSubmitting || !isValid}
                      className="btn"
                    >
                      {popuploading && <Loading></Loading>}
                      {popuploading ? "Submitting..." : "Done"}
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={deleteRestaurantLocation}
          popuploading={popuploading}
          title="Delete Location"
          description="Are you sure you want to delete this location?  The location data will be erased and this process is irreversible."
          buttonName="Yes, Delete!"
        />
      )}
    </div>
  );
}

export default EditRestaurantLocation;
