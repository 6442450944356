import React, { useEffect, useState } from "react";
import Layout from "../../../layouts/dashboard-layout";
import "./notifications.scss";
import AcuteIcon from "../../../../assets/images/acute-icon.svg";
import NoAvatar from "../../../../assets/images/no-avatar.png";
import NotificationSendPopup from "./notificationSendPopup";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsData } from "../../../../store/reducers/notification";
import { constant } from "../../../constants";
import moment from "moment/moment";
import Loading from "../../../components/loading";
import ReactPaginate from "react-paginate";

const Notifications = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [isNotificationSendPopupOpen, setIsNotificationSendPopupOpen] =
    useState(false);

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [lastPage, setLastPage] = useState(0);

  const notification = useSelector((state) => state.notification);

  const dispatch = useDispatch();

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getNotifications = (p = page, s = size, q = searchQuery) => {
    const payload = {
      page: p,
      size: s,
      keywords: q || undefined,
    };

    dispatch(getNotificationsData(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setTotalItems(response?.payload?.pagination?.totalItems);
          setLastPage(response?.payload?.pagination?.lastPage);
          return;

        default:
          return;
      }
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setPage(1);
      if (searchQuery) {
        getNotifications(1, size, searchQuery);
      } else {
        getNotifications(1, size, "");
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [searchQuery]);

  const paginate = (e) => {
    const selectedPage = e.selected;
    setPage(selectedPage + 1);
    getNotifications(selectedPage + 1, size, searchQuery);
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Notifications You’ve Sent</h2>
          <div className="iconSce">
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <button
              className="sendNotificationBtn"
              onClick={() => setIsNotificationSendPopupOpen(true)}
            >
              Send Notification
            </button>
          </div>
        </div>

        <div className="notificationListSec">
          {notification?.isFethingNotification &&
          notification?.notificationsData?.length <= 0 ? (
            <div className="loadingSec">
              <Loading />
            </div>
          ) : (
            notification?.notificationsData?.map((item) => {
              return (
                <div
                  className="notificationListCard"
                  key={item?.adminNotificationId}
                >
                  <div className="notificationCardTopSec">
                    <h1>{item?.title}</h1>
                    <div>
                      <img src={AcuteIcon} alt="acuteIcon" />
                      <p>
                        {moment(item?.notificationCreatedAt).format(
                          "MMM DD, hh:mm A"
                        )}
                      </p>
                    </div>
                  </div>
                  <p>{item?.description}</p>
                  <div className="userImageSec">
                    {item?.users?.slice(0, 5)?.map((user) => {
                      return (
                        <img
                          src={
                            user?.profilePicture
                              ? user?.profilePicture
                              : NoAvatar
                          }
                          alt="noAvatar"
                          key={user?.userId}
                        />
                      );
                    })}
                    {item?.users?.length > 5 && (
                      <span>
                        {item?.users?.slice(5, item?.users?.length).length <= 99
                          ? `+${
                              item?.users?.slice(5, item?.users?.length).length
                            }`
                          : `+99`}
                      </span>
                    )}
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
      {totalItems > page ? (
        <ReactPaginate
          previousLabel={<i className="bx bx-chevrons-left"></i>}
          nextLabel={<i className="bx bx-chevrons-right"></i>}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={lastPage}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={paginate}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
          forcePage={page - 1}
        />
      ) : null}
      {isNotificationSendPopupOpen && (
        <NotificationSendPopup
          setIsNotificationSendPopupOpen={setIsNotificationSendPopupOpen}
          getNotifications={() => {
            getNotifications();
          }}
        />
      )}
    </Layout>
  );
};

export default Notifications;
