import React, { useState, useEffect } from "react";
import Layout from "../../../layouts/dashboard-layout";
import Loading from "../../../components/loading";
import DeletePopup from "../../../components/delete-popup";
import { useDispatch, useSelector } from "react-redux";
import { selectFoodItems } from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import {
  selectIsDeleteModalOpen,
  selectIsEditMenuItemModalOpen,
} from "../../../../store/selectors/modalSelectors";
import {
  deleteModalHide,
  deleteModalOpen,
  editMenuItemModalHide,
  editMenuItemModalOpen,
} from "../../../../store/reducers/modals";
import {
  deletefoodItems,
  getfoodItems,
  ignorefoodItems,
  setGetfoodItemsToTrue,
} from "../../../../store/reducers/manageFoodItems";
import { formatDateTime } from "../../../components/formatAndValidate";
import AddMenuItemPopup from "../menu/addMenuItemPopup";
import "./manage-items.scss";

const ManageItems = () => {
  const foodItemsData = useSelector(selectFoodItems);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const [foodItems, setFoodItems] = useState([]);
  const [sortDirection, setSortDirection] = useState("asc");
  const [sortBy, setSortBy] = useState("");
  const [selectedFoodItemId, setSelectedFoodItemId] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const isEditMenuItemPopupOpen = useSelector(selectIsEditMenuItemModalOpen);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getfoodItem = async () => {
    await dispatch(
      getfoodItems({ searchQuery, orderBy: sortBy, orderType: sortDirection })
    ).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setFoodItems(response?.payload?.food_reports);
          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
  };

  useEffect(() => {
    dispatch(setGetfoodItemsToTrue());

    const handler = setTimeout(() => {
      getfoodItem();
    }, 500);

    dispatch(deleteModalHide());
    dispatch(editMenuItemModalHide());
    return () => {
      dispatch(deleteModalHide());
      dispatch(editMenuItemModalHide());
      clearTimeout(handler);
    };
  }, [dispatch, searchQuery, sortBy, sortDirection]);

  const handleDeleteClick = (foodId) => {
    setSelectedFoodItemId(foodId);
    dispatch(deleteModalOpen());
  };

  const removeAccess = async (foodItemId) => {
    dispatch(deletefoodItems(foodItemId)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          getfoodItem();
          return;

        default:
          return;
      }
    });
  };

  const handleIgnoreClick = async (foodId) => {
    dispatch(ignorefoodItems(foodId)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          getfoodItem();
          return;

        default:
          return;
      }
    });
  };

  const handleSort = (criterion) => {
    setSortBy(criterion);
    setSortDirection((prevDirection) =>
      prevDirection === "asc" ? "desc" : "asc"
    );
  };

  const openEditMenuItemPopup = (foodId) => {
    dispatch(editMenuItemModalOpen());
    setSelectedFoodItemId(foodId);
    setIsPopupOpen(true);
  };

  const closeEditMenuItemPopup = () => {
    dispatch(editMenuItemModalHide());
  };

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Marked For Removal</h2>
          <div className="iconSce">
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
          </div>
        </div>

        <div style={{ position: "relative" }} className="tableSec">
          {foodItemsData?.isLoading && (
            <div className="tableLoader">
              <div className="spinnerLoad">
                <Loading></Loading>
              </div>
            </div>
          )}
          {isDataLoaded && (
            <table>
              <thead>
                <tr>
                  <th scope="col">
                    Item Name{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("foodItemName")}
                    />
                  </th>
                  <th scope="col">
                    # of Requests{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("count")}
                    />
                  </th>
                  <th scope="col">
                    Latest Request{" "}
                    <i
                      className={`bx bx-chevron-${
                        sortDirection === "asc" ? "down" : "up"
                      }`}
                      onClick={() => handleSort("createdAt")}
                    />
                  </th>
                  <th style={{ width: "5%" }} scope="col"></th>
                  <th style={{ textAlign: "right" }} scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {foodItems?.length <= 0 && (
                  <tr style={{ borderBottom: "none" }}>
                    <td colSpan="3">
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "17px",
                          padding: "50px 0 0",
                        }}
                      >
                        No Data Found
                      </div>
                    </td>
                  </tr>
                )}

                {foodItems?.map((item) => (
                  <tr key={item?.foodId}>
                    <td data-label="Item Name">{item?.foodItemName}</td>
                    <td data-label="# of Requests">{item?.reportCount}</td>
                    <td data-label="Latest Request">
                      {item?.lastReportedAt
                        ? formatDateTime(item?.lastReportedAt)
                        : "N/A"}
                    </td>
                    <td className="textEnd" data-label="Actions">
                      <div className="iconSce">
                        <i
                          className="bx bxs-pencil"
                          onClick={() => openEditMenuItemPopup(item?.foodId)}
                        ></i>
                      </div>
                    </td>
                    <td className="textEnd" data-label="Actions">
                      <div
                        className="ignoreBtn"
                        onClick={() => handleIgnoreClick(item?.foodId)}
                      >
                        Ignore
                      </div>
                      <div
                        className="revraBtn"
                        onClick={() => handleDeleteClick(item?.foodId)}
                      >
                        Remove Item
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => removeAccess(selectedFoodItemId)}
          popuploading={foodItemsData?.isPopupLoading}
          title="Remove Item"
          description="Are you sure you want to remove this item? It won't show up in any user's recommendations anymore."
          buttonName="Remove Item"
        />
      )}
      {isPopupOpen && (
        <AddMenuItemPopup
          isOpen={isEditMenuItemPopupOpen}
          onClose={closeEditMenuItemPopup}
          isEditMode={isEditMenuItemPopupOpen}
          foodItemId={selectedFoodItemId}
          getFoodsData={getfoodItem}
        />
      )}
    </Layout>
  );
};

export default ManageItems;
