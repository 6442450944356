import React, { useEffect } from "react";
import "./index.scss";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../../components/input";
import { useDispatch, useSelector } from "react-redux";
import { constant } from "../../../../constants";
import toast from "react-hot-toast";
import {
  mergeRestaurant,
  resetLoadingState,
} from "../../../../../store/reducers/restaurantMerger";
import { selectRestaurantMergerData } from "../../../../../store/selectors/dashboardSelectors";
import Button from "../../../../components/button";
import Loading from "../../../../components/loading";
import { useNavigate } from "react-router-dom";
import { restaurantId } from "../../../../../store/reducers/restaurantLocationDetail";

const initialValues = {
  newRestaurantName: "",
};

const MergeRestaurantPopup = ({
  closePopup,
  checkedIds,
  setCheckedIds,
  resetSelectionAndRefresh,
  setCheckedRestaurants,
  checkedRestaurants,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const mergeRestaurantData = useSelector(selectRestaurantMergerData);

  useEffect(() => {
    return () => {
      dispatch(resetLoadingState());
    };
  }, [dispatch]);

  const handleMerge = async (values) => {
    const payload = {
      restaurantIds: checkedIds,
      newRestaurantName: values.newRestaurantName?.trim(),
    };
    dispatch(mergeRestaurant(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          dispatch(resetLoadingState());
          toast.success(
            response?.payload?.message || "Restaurants merged successfully"
          );
          closePopup();
          resetSelectionAndRefresh();
          handleRestauarntLocationNavigation(response?.payload?.restaurantId);
          return;

        default:
          dispatch(resetLoadingState());
          return;
      }
    });
  };

  const handleRestauarntLocationNavigation = async (id) => {
    dispatch(restaurantId(id));
    navigate(`/dashboard/restaurant-location-owner/view-detail/${id}`);
  };

  const handleRemove = (id) => {
    setCheckedIds((prevCheckedIds) =>
      prevCheckedIds.filter((item) => item !== id)
    );
    setCheckedRestaurants((prevCheckedName) =>
      prevCheckedName.filter((item) => item.id !== id)
    );
  };

  return (
    <div className="popup delresPopup">
      {mergeRestaurantData?.isPopupLoading && (
        <div className="overlay">
          <div className="loader-content">
            <Loading />
            <div className="loading-text">
              <p>Merging in Progress...</p>
              <p style={{ lineHeight: 1.7, paddingTop: "10px" }}>
                The selected restaurants are being merged into a single record.
                This process may take a few minutes. Please do not close this
                window until the operation is complete.
              </p>
              <p style={{ paddingTop: "10px" }}>Thank you for your patience.</p>
            </div>
          </div>
        </div>
      )}
      <div className="popup-content">
        <h2>Restaurant Merge</h2>
        <p className="paraText1">
          Merging restaurants will permanently delete all selected restaurants
          and consolidate their data into a single restaurant record. Any
          accounts associated with the selected restaurants will also be
          removed.
        </p>
        <p className="paraText">Please note, this process is irreversible.</p>
        <p className="paraText">
          The merging process may take several minutes. Do not close this window
          until it is complete.
        </p>
        <p className="paraText">Would you like to proceed?</p>
        <div
          className="scrollable-container"
          style={{
            display: "flex",
            gap: "8px",
            flexWrap: "wrap",
            marginTop: "10px",
            maxHeight: "100px",
            overflow: "auto",
            justifyContent: "center",
          }}
        >
          {checkedRestaurants.map(({ id, name }) => (
            <div
              key={id}
              style={{
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                backgroundColor: "#ffc107",
                borderRadius: "20px",
                color: "#ffffff",
                fontSize: "12px",
              }}
            >
              {name}
              <span
                style={{
                  marginLeft: "8px",
                  backgroundColor: "#ffffff",
                  color: "#ffc107",
                  borderRadius: "50%",
                  width: "20px",
                  height: "20px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
                onClick={() => handleRemove(id)}
              >
                &times;
              </span>
            </div>
          ))}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            newRestaurantName: Yup.string()
              .required("Restaurant Name is required")
              .max(255, "Restaurant Name cannot exceed 255 characters")
              .trim(),
          })}
          onSubmit={(values, { setSubmitting }) =>
            handleMerge(values, setSubmitting)
          }
        >
          {() => {
            return (
              <Form>
                <div>
                  <div className="flexRow">
                    <div className="formCol resformCol">
                      <div className="form-group">
                        <label htmlFor="newRestaurantName">
                          Restaurant Name
                        </label>
                        <Field
                          type="text"
                          name="newRestaurantName"
                          id="newRestaurantName"
                          placeholder="Restaurant Name"
                          component={Input}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div style={{ marginTop: 20 }} className="flxendBtn">
                  <Button
                    type="submit"
                    className="delCancelBtnpop4"
                    onClick={closePopup}
                  >
                    Cancel
                  </Button>
                  <div>
                    {checkedIds.length > 1 ? (
                      <Button type="submit" className="delSubmitBtn2">
                        Merge
                      </Button>
                    ) : (
                      <Button
                        type="button"
                        className="delSubmitBtn2 disSubmitBtn"
                      >
                        Merge
                      </Button>
                    )}
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default MergeRestaurantPopup;
