import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Layout from "../../../layouts/dashboard-layout";
import {
  resetUploadProgress,
  uploadFoodItemsFile,
  uploadRestaurantFile,
} from "../../../../store/reducers/importFoodCSV";
import { constant } from "../../../constants";
import "./importCSVFile.scss";
import { selectImportCSVFileData } from "../../../../store/selectors/dashboardSelectors";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #ced4da",
    borderRadius: "4px",
    minHeight: "38px",
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "8px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#ffc107",
    borderRadius: "20px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    color: "#ffc107",
    marginRight: "5px",
    marginLeft: "5px",
    marginTop: "5px",
    marginBottom: "5px",
    width: "20px",
    height: "20px",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};

const ImportCSVFile = () => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const progressData = useSelector(selectImportCSVFileData);
  const progress = progressData?.progress;

  const [isImporting, setIsImporting] = useState(false);

  const options = [
    { value: "foodItems", label: "Food Items" },
    { value: "restaurant", label: "Restaurant" },
  ];

  const handleSelectChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    setFileName(file ? file.name : "");
  };

  const handleSubmit = async (values, { resetForm }) => {
    setIsImporting(true);

    if (!file) {
      toast.error("Please select a file first");
      return;
    }
    const formData = new FormData();

    if (selectedOption.value === "restaurant") {
      formData.append("restaurant_csv", file);
    } else {
      formData.append("food_csv", file);
    }

    const uploadFileFunction =
      selectedOption.value === "restaurant"
        ? () => dispatch(uploadRestaurantFile(formData))
        : () => dispatch(uploadFoodItemsFile(formData));
    uploadFileFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          toast.success(
            response?.payload?.message || "File uploaded successfully"
          );
          resetForm();
          setSelectedOption(null);
          setFile(null);
          setFileName("");

          setIsImporting(false);
          return;

        case constant.thunkStatus.REJECTED:
          setIsImporting(false);
          return;

        default:
          return;
      }
    });
  };

  useEffect(() => {
    if (progress) {
      dispatch(dispatch(resetUploadProgress()));
    }
  }, [dispatch]);

  return (
    <Layout>
      <div className="home-content">
        <div className="bodyTopsec">
          <h2>Import CSV</h2>
        </div>
        <div className="repeatSec2 importCsvrightGap">
          <Formik
            initialValues={{
              importType: { label: "", value: "" },
              uploadFile: "",
            }}
            validationSchema={Yup.object().shape({
              importType: Yup.object().shape({
                label: Yup.string().required("Please select an option").trim(),
                value: Yup.string().required("Please select an option").trim(),
              }),
              uploadFile: Yup.mixed().required("Please select a file"),
            })}
            onSubmit={async (values, { resetForm }) => {
              handleSubmit(values, { resetForm });
            }}
          >
            {({ setFieldValue }) => {
              return (
                <Form>
                  <div className="flexRow">
                    <div className="formCol">
                      <div className="form-group">
                        <label>Import CSV for</label>
                        <Select
                          styles={customStyles}
                          placeholder="Select One"
                          value={selectedOption}
                          options={options}
                          onChange={(e) => {
                            handleSelectChange(e);
                            setFieldValue("importType", e);
                          }}
                          name="importType"
                        />
                        <div className="error-wrapper">
                          <ErrorMessage name="importType.label" />
                        </div>
                      </div>
                    </div>

                    <div className="formCol">
                      <div className="form-group">
                        <label>Choose File</label>
                        <div
                          className="file-upload-wrapper"
                          data-text={fileName ? fileName : "Select a file"}
                        >
                          <Field name="uploadFile">
                            {({ form }) => (
                              <input
                                type="file"
                                id="uploadFile"
                                onChange={(e) => {
                                  handleFileChange(e);
                                  form.setFieldValue(
                                    "uploadFile",
                                    e.target.files[0]
                                  );
                                  e.target.value = "";
                                }}
                              />
                            )}
                          </Field>
                        </div>
                        <div className="error-wrapper">
                          <ErrorMessage name="uploadFile" />
                        </div>
                      </div>
                    </div>
                  </div>
                  {progress > 0 && (
                    <div className="progress-bar">
                      <div
                        className="progress-bar-fill"
                        style={{ width: `${progress}%` }}
                      >
                        {progress}%
                      </div>
                    </div>
                  )}
                  <div style={{ marginTop: 50 }} className="flxendBtn">
                    {progress || isImporting ? (
                      <Button
                        type="button"
                        className="importBtn disImportBtn"
                        disabled={true}
                      >
                        Import
                      </Button>
                    ) : (
                      <Button type="submit" className="importBtn">
                        Import
                      </Button>
                    )}
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </Layout>
  );
};

export default ImportCSVFile;
