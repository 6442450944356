import React, { useEffect, useRef, useState } from "react";
import AddMenuItemPopup from "./addMenuItemPopup";
import CategoryPopup from "./categoryPopup";
import { Link } from "react-router-dom";
import FilterIcon from "../../../../assets/images/filter-icon.svg";
import Loading from "../../../components/loading";
import FilterCategoryPopup from "./filterCategoryPopup";
import DeletePopup from "../../../components/delete-popup";
import { useDispatch, useSelector } from "react-redux";
import { deleteMenuItem, getMenuItem } from "../../../../store/reducers/menu";
import {
  selectMenuItemsData,
  selectRestaurantLocationData,
} from "../../../../store/selectors/dashboardSelectors";
import { constant } from "../../../constants";
import {
  selectIsAddMenuItemModalOpen,
  selectIsDeleteModalOpen,
  selectIsEditCategoryModalOpen,
  selectIsEditMenuItemModalOpen,
  selectIsFilterCategoryModalOpen,
} from "../../../../store/selectors/modalSelectors";
import {
  addMenuItemModalHide,
  addMenuItemModalOpen,
  deleteModalHide,
  deleteModalOpen,
  editCategoryModalHide,
  editCategoryModalOpen,
  editMenuItemModalHide,
  editMenuItemModalOpen,
  filterCategoryModalHide,
  filterCategoryModalOpen,
} from "../../../../store/reducers/modals";
import {
  deleteAdminMenuItem,
  getAdminMenuItem,
} from "../../../../store/reducers/menu";
import { userData } from "../../../../store/selectors/authSelectors";
import "./menu.scss";
import "./menu.css";
import {
  getAdminFoodCategory,
  getFoodCategory,
} from "../../../../store/reducers/foodCategory";

const MenuComponent = ({
  searchQueryAdmin,
  isEditModeAdmin,
  setSelectedCategoriesForAdmin = () => {},
}) => {
  const dispatch = useDispatch();
  const user = useSelector(userData);
  const menuItemData = useSelector(selectMenuItemsData);
  const isAddMenuItemPopupOpen = useSelector(selectIsAddMenuItemModalOpen);
  const isEditMenuItemPopupOpen = useSelector(selectIsEditMenuItemModalOpen);
  const isEditCategoryPopupOpen = useSelector(selectIsEditCategoryModalOpen);
  const isDeletePopup = useSelector(selectIsDeleteModalOpen);
  const isfilterCategoryPopupOpen = useSelector(
    selectIsFilterCategoryModalOpen
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [prevEditMode, setPrevEditMode] = useState(false);
  const [foods, setFoods] = useState(menuItemData?.menuItems);
  const [selectedFoodId, setSelectedFoodId] = useState(null);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [selectedFoodItemId, setSelectedFoodItemId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);

  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantId = restaurantDetailData?.restaurantId;
  const userIsRestaurant =
    user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin;
  const currentEditMode = userIsRestaurant ? isEditMode : isEditModeAdmin;

  const foodCategory = useSelector((state) => state?.foodCategory);

  const mainMenuRef = useRef(null);

  const closeAddMenuItemPopup = () => {
    dispatch(addMenuItemModalHide());
    dispatch(editMenuItemModalHide());
  };
  const openEditMenuItemPopup = (foodItemId) => {
    dispatch(editMenuItemModalOpen());
    setSelectedFoodItemId(foodItemId);
  };
  const closeEditMenuItemPopup = () => {
    dispatch(editMenuItemModalHide());
    dispatch(addMenuItemModalHide());
  };
  const openEditCategoryPopup = (categoryId) => {
    dispatch(editCategoryModalOpen());
    setSelectedCategoryId(categoryId);
  };

  const toggleEditMode = () => {
    if (!currentEditMode) {
      setPrevEditMode(currentEditMode);
    }
    setIsEditMode(!currentEditMode);
  };
  const toggleToPrevEditMode = () => {
    setIsEditMode(prevEditMode);
  };

  const handleSearchInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const getCategories = async () => {
    setIsDataLoaded(false);
    console.log("call hoche");
    const fetchCategories = userIsRestaurant
      ? () => dispatch(getFoodCategory())
      : () => dispatch(getAdminFoodCategory(restaurantId));

    fetchCategories().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const grouped = response?.payload?.categories?.reduce((acc, item) => {
            if (!acc[item.categoryId]) {
              acc[item.categoryId] = {
                categoryId: item.categoryId,
                categoryName: item.categoryName,
                data: [],
                totalItems: 0,
                lastPage: 0,
                page: 1,
                size: 10,
                isCollapsed: true,
                isFetchingFoods: false,
                foodCount: item.foodCount,
              };
            }

            return acc;
          }, {});

          const sortedCategories = Object.values(grouped).sort((a, b) => {
            if (a.categoryId === -1) return 1;
            if (b.categoryId === -1) return -1;
            return a.categoryName.localeCompare(b.categoryName);
          });

          setFoods(sortedCategories);

          setIsDataLoaded(true);
          return;

        default:
          return;
      }
    });
    dispatch(addMenuItemModalHide());
    dispatch(deleteModalHide());
    dispatch(editCategoryModalHide());
    dispatch(editMenuItemModalHide());
    dispatch(filterCategoryModalHide());
    return () => {
      dispatch(addMenuItemModalHide());
      dispatch(deleteModalHide());
      dispatch(editCategoryModalHide());
      dispatch(editMenuItemModalHide());
      dispatch(filterCategoryModalHide());
    };
  };

  const getFoodsFromCategory = (page, categoryId) => {
    const selectedCategory = foods?.find(
      (food) => food.categoryId === categoryId
    );

    const fetchFoodsDataFromCategory = userIsRestaurant
      ? () =>
          dispatch(
            getMenuItem({
              restaurantId: user?.restaurant?.restaurantId,
              pageNum: page || selectedCategory?.page,
              pageSize: selectedCategory?.size || 99999999,
              selectedCategories: categoryId ? [categoryId] : [],
              searchQuery: searchQuery,
            })
          )
      : () =>
          dispatch(
            getAdminMenuItem({
              restaurantId,
              pageNum: page || selectedCategory?.page,
              pageSize: selectedCategory?.size || 99999999,
              selectedCategories: categoryId ? [categoryId] : [],
              searchQuery: searchQueryAdmin,
            })
          );
    fetchFoodsDataFromCategory().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setIsDataLoaded(true);
          if (categoryId) {
            if (mainMenuRef?.current) mainMenuRef?.current?.scrollIntoView();

            setFoods((prev) => {
              return prev.map((item) => {
                if (item?.categoryId === categoryId) {
                  return {
                    ...item,
                    data: [...item.data, ...response?.payload?.foods],
                    totalItems: response?.payload?.pagination?.totalItems,
                    lastPage: response?.payload?.pagination?.lastPage,
                    isFetchingFoods: false,
                  };
                }
                return item;
              });
            });
          } else {
            const groupedFoods = response?.payload?.foods?.reduce(
              (acc, item) => {
                if (!acc[item.foodCategory.categoryId]) {
                  acc[item.foodCategory.categoryId] = [];
                }

                acc[item.foodCategory.categoryId].push(item);

                return acc;
              },
              {}
            );

            const groupedCategories = response?.payload?.foods?.reduce(
              (acc, item) => {
                if (!acc[item.foodCategory.categoryId]) {
                  acc[item.foodCategory.categoryId] = {
                    categoryId: item.foodCategory.categoryId,
                    categoryName: item.foodCategory.categoryName,
                    data: groupedFoods[item.foodCategory.categoryId],
                    isCollapsed: false,
                    foodCount:
                      groupedFoods[item.foodCategory.categoryId].length,
                  };
                }

                return acc;
              },
              {}
            );

            const sortedCategories = Object.values(groupedCategories).sort(
              (a, b) => {
                if (a.categoryId === -1) return 1;
                if (b.categoryId === -1) return -1;
                return a.categoryName.localeCompare(b.categoryName);
              }
            );

            setFoods(sortedCategories);
          }
          return;

        case constant.thunkStatus.REJECTED:
          setFoods((prev) => {
            return prev.map((item) => {
              if (item?.categoryId === categoryId) {
                return {
                  ...item,
                  isFetchingFoods: false,
                };
              }
              return item;
            });
          });
          return;

        default:
          return;
      }
    });
  };

  useEffect(() => {
    // const handler = setTimeout(() => {
    if (searchQuery !== "" || searchQueryAdmin !== "") {
      getFoodsFromCategory(1, null);
    } else {
      getCategories();
    }
    // }, 500);

    // return () => {
    //   clearTimeout(handler);
    // };
  }, [searchQuery, searchQueryAdmin]);

  const handleDeleteClick = (adminId) => {
    setSelectedFoodId(adminId);
    dispatch(deleteModalOpen());
  };

  const removeAccess = async (foodItemId) => {
    const deleteMenuItemFunction = userIsRestaurant
      ? () => dispatch(deleteMenuItem(foodItemId))
      : () => dispatch(deleteAdminMenuItem({ foodId: foodItemId }));
    deleteMenuItemFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          getCategories();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className={userIsRestaurant ? "homeContent prcon20" : undefined}>
      {userIsRestaurant && (
        <div className="bodyTopsec">
          <h2 className="titleWidth50">Manage Your Menu</h2>
          <div className="iconSce">
            <div className="filterSec">
              <Link>
                <img
                  src={FilterIcon}
                  onClick={() => dispatch(filterCategoryModalOpen())}
                  alt="FilterIcon"
                />
              </Link>
            </div>
            <div className="searchSec">
              <input
                placeholder="Search"
                name="myInput"
                value={searchQuery}
                onChange={handleSearchInputChange}
              />
            </div>
            <div style={{ display: "flex" }}>
              {currentEditMode ? (
                <a className="doneBtn" onClick={toggleToPrevEditMode}>
                  Done
                </a>
              ) : (
                <i className="bx bxs-pencil" onClick={toggleEditMode}></i>
              )}
              <i
                onClick={() => dispatch(addMenuItemModalOpen())}
                className="bx bx-plus"
              ></i>
            </div>
          </div>
        </div>
      )}

      {foodCategory?.isLoading || !isDataLoaded ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "50px",
          }}
        >
          <Loading></Loading>
        </div>
      ) : (
        <div style={{ position: "relative" }}>
          {(foods?.length <= 0 ||
            foods
              ?.map((food) => food?.foodCount)
              .every((item) => item <= 0)) && (
            <div style={{ borderBottom: "none" }}>
              <div
                style={{
                  textAlign: "center",
                  fontSize: "17px",
                  padding: "100px 0 0",
                  width: " 96.9%",
                }}
              >
                No Data Found
              </div>
            </div>
          )}
          {foods?.map((food, index) => {
            if (
              (selectedCategories?.length > 0 &&
                !selectedCategories.includes(food?.categoryId)) ||
              food?.foodCount <= 0
              // && food?.categoryId === -1
            ) {
              return null;
            } else {
              return (
                <div
                  key={food?.categoryId}
                  className={
                    userIsRestaurant
                      ? "repeatSec"
                      : "repeatSec repeatSecmargin3"
                  }
                >
                  <div
                    className="categoryHeaderSection"
                    onClick={() => {
                      setFoods((prev) =>
                        prev.map((item) => {
                          if (item?.categoryId === food?.categoryId) {
                            if (item?.data?.length <= 0) {
                              getFoodsFromCategory(1, item?.categoryId);
                              return {
                                ...item,
                                isCollapsed: !item?.isCollapsed,
                                isFetchingFoods: true,
                              };
                            }
                            return {
                              ...item,
                              isCollapsed: !item?.isCollapsed,
                            };
                          }
                          return item;
                        })
                      );
                    }}
                  >
                    <h3
                      style={userIsRestaurant ? { width: "70%" } : undefined}
                      className={
                        user.userType !== "Restaurant" ? "titleWidth" : ""
                      }
                    >
                      {food?.categoryId === -1
                        ? "Without Category"
                        : `${food?.categoryName ?? ""}`}
                      <span style={{ color: "#32807f" }}>{`${
                        food?.foodCount ? "(" + food?.foodCount + ")" : ""
                      }`}</span>
                      {currentEditMode && food.categoryId !== -1 && (
                        <i
                          className="bx bxs-pencil"
                          onClick={(e) => {
                            e.stopPropagation();
                            openEditCategoryPopup(food.categoryId);
                          }}
                        ></i>
                      )}
                    </h3>
                    {food?.categoryId && (
                      <i
                        class={`bx ${
                          food.isCollapsed ? "bx-chevron-down" : "bx-chevron-up"
                        }`}
                      ></i>
                    )}
                  </div>
                  {!food.isCollapsed && (
                    <div class="scrollbar22" id="style-6" ref={mainMenuRef}>
                      <div class="force-overflow22">
                        <div
                          className={
                            userIsRestaurant ? "flexRow" : "flexRow flexRow2"
                          }
                        >
                          {food?.data?.map((item) => (
                            <div
                              key={item?.foodId}
                              className={
                                userIsRestaurant ? "boxCol" : "boxCol boxCol3"
                              }
                            >
                              {currentEditMode ? (
                                <div className="flexSec">
                                  <div
                                    style={
                                      userIsRestaurant
                                        ? { paddingLeft: "20px", width: "55%" }
                                        : { width: "65%" }
                                    }
                                    className=""
                                  >
                                    <p>{item?.foodItemName}</p>
                                    <p>${item?.price}</p>
                                  </div>{" "}
                                  <div
                                    className="iconSec"
                                    style={
                                      user.userType !== "Restaurant"
                                        ? { paddingRight: "0" }
                                        : undefined
                                    }
                                  >
                                    <i
                                      className="bx bxs-trash"
                                      onClick={() =>
                                        handleDeleteClick(item?.foodId)
                                      }
                                    ></i>
                                    <i
                                      className="bx bxs-pencil"
                                      onClick={() =>
                                        openEditMenuItemPopup(item?.foodId)
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ) : (
                                <div
                                  style={
                                    userIsRestaurant
                                      ? { padding: "0 20px" }
                                      : undefined
                                  }
                                  className=""
                                >
                                  <p>{item.foodItemName}</p>
                                  <p>${item?.price}</p>
                                </div>
                              )}
                            </div>
                          ))}
                          {food?.isFetchingFoods ? (
                            <Loading />
                          ) : food?.totalItems > 0 &&
                            food?.totalItems > food?.data?.length ? (
                            <p
                              className="loadMoreText"
                              onClick={() => {
                                setFoods((prev) =>
                                  prev.map((foodItem) => {
                                    if (
                                      foodItem.categoryId === food.categoryId
                                    ) {
                                      return {
                                        ...foodItem,
                                        page: foodItem.page + 1,
                                        isFetchingFoods: true,
                                      };
                                    }
                                    return foodItem;
                                  })
                                );
                                getFoodsFromCategory(
                                  food.page + 1,
                                  food.categoryId
                                );
                              }}
                            >
                              Load More
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              );
            }
          })}
        </div>
      )}

      {isDeletePopup && (
        <DeletePopup
          closePopup={() => dispatch(deleteModalHide())}
          handleDelete={() => removeAccess(selectedFoodId)}
          popuploading={menuItemData?.isPopupLoading}
          title="Delete Confirmation"
          description="Are you sure you want to Delete the Food Item?"
          buttonName="Yes, Delete!"
        />
      )}
      <AddMenuItemPopup
        isOpen={isAddMenuItemPopupOpen || isEditMenuItemPopupOpen}
        onClose={
          currentEditMode ? closeEditMenuItemPopup : closeAddMenuItemPopup
        }
        isEditMode={isEditMenuItemPopupOpen}
        getFoodsData={getCategories}
        foodItemId={selectedFoodItemId}
      />
      <CategoryPopup
        isOpen={isEditCategoryPopupOpen}
        onClose={() => dispatch(editCategoryModalHide())}
        isEditMode={isEditCategoryPopupOpen}
        categoryId={selectedCategoryId}
        getFoodsData={getCategories}
      />
      <FilterCategoryPopup
        isOpen={isfilterCategoryPopupOpen}
        onClose={() => dispatch(filterCategoryModalHide())}
        selectedCategories={selectedCategories}
        setSelectedCategories={setSelectedCategories}
        setSelectedCategoriesForAdmin={setSelectedCategoriesForAdmin}
      />
    </div>
  );
};

export default MenuComponent;
