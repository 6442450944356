import React, { useEffect, useState } from "react";
import "./notificationSendPopup.scss";
import { ErrorMessage, Formik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getAppUsersData } from "../../../../store/reducers/user";
import { constant } from "../../../constants";
import { sendNotification } from "../../../../store/reducers/notification";

const NotificationSendPopup = ({
  setIsNotificationSendPopupOpen = () => {},
  getNotifications = () => {},
}) => {
  const [usersData, setUsersData] = useState([]);

  const notification = useSelector((state) => state.notification);
  const user = useSelector((state) => state.user);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAppUsersData()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setUsersData(
            response.payload.users?.map((item) => {
              return {
                label: `${item?.firstName} ${item?.lastName}`,
                value: item?.userId,
              };
            })
          );
          return;

        default:
          return;
      }
    });
  }, [dispatch]);

  const send = (values, resetForm) => {
    const payload = {
      description: values.message,
      title: values.title,
      userIds: values.appUsers.map((item) => item.value),
    };

    dispatch(sendNotification(payload)).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          resetForm();
          setIsNotificationSendPopupOpen(false);
          getNotifications();
          return;

        default:
          return;
      }
    });
  };

  return (
    <div className="popup">
      <div className="popup-content longWith">
        <h2>Create Notification</h2>

        <Formik
          initialValues={{
            appUsers: [],
            title: "",
            message: "",
          }}
          validationSchema={Yup.object().shape({
            appUsers: Yup.array()
              .min(1, "At least one user must be selected")
              .required("At least one user must be selected"),
            title: Yup.string().required("Subject is required"),
            message: Yup.string().required("Message is required"),
          })}
          onSubmit={(values, { resetForm }) => {
            send(values, resetForm);
          }}
        >
          {({ values, setFieldValue, handleChange, handleSubmit }) => {
            return (
              <div className="form-section">
                <div className="input-section">
                  <label>App User*</label>
                  <Select
                    isMulti
                    name="appUsers"
                    options={[
                      { label: "Select All", value: "selectAll" },
                      ...usersData,
                    ]}
                    classNamePrefix="Select"
                    onChange={(selectedOptions) => {
                      if (
                        selectedOptions
                          .map((item) => item.value)
                          .includes("selectAll")
                      ) {
                        setFieldValue("appUsers", usersData);
                      } else {
                        setFieldValue("appUsers", selectedOptions);
                      }
                    }}
                    value={values.appUsers}
                    className="selectInput"
                    menuPlacement="auto"
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        maxHeight: "150px",
                        overflowY: "auto",
                        textAlign: "left",
                      }),
                      placeholder: (provided) => ({
                        ...provided,
                        textAlign: "left",
                      }),
                    }}
                  />
                  <span style={{ color: "red" }}>
                    <ErrorMessage name="appUsers" />
                  </span>
                </div>
                <div className="input-section">
                  <label>Notification Subject*</label>
                  <input
                    type="text"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                  />
                  <span style={{ color: "red" }}>
                    <ErrorMessage name="title" />
                  </span>
                </div>
                <div className="input-section">
                  <label>Notification Body Text*</label>
                  <textarea
                    type="text"
                    name="message"
                    value={values.message}
                    onChange={handleChange}
                    rows={5}
                  ></textarea>
                  <span style={{ color: "red" }}>
                    <ErrorMessage name="message" />
                  </span>
                </div>
                <div className="btnSec">
                  <button
                    className="cancelBtn"
                    onClick={() => {
                      setIsNotificationSendPopupOpen(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="sendNotificationBtn"
                    onClick={handleSubmit}
                    disabled={
                      user?.isAppUsersLoading ||
                      notification?.isSendingNotification
                    }
                  >
                    {notification?.isSendingNotification
                      ? "Sending Notification"
                      : "Send Notification"}
                  </button>
                </div>
              </div>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default NotificationSendPopup;
