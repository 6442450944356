import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  token: null,
  data: null,
  loginType: null,
  restaurantDealId: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    loginType: (state, action) => {
      state.loginType = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    logoutUser: () => {
      return initialState;
    },
    storedData: (state, action) => {
      state.data = action.payload;
    },
    setRestaurantDealId: (state, action) => {
      state.restaurantDealId = action.payload;
    },
  },
});

export const {
  loginType,
  setToken,
  logoutUser,
  storedData,
  setRestaurantDealId,
} = authSlice.actions;

export default authSlice.reducer;
