import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { fetchGetRequest, fetchPatchRequest } from "../../libs/network";
import endpoints from "../../utils/endpoints";
import toast from "react-hot-toast";

const initialState = {
  isLoading: false,
  restaurants: null,
  isPopupLoading: false,
};

export const getAllRestaurant = createAsyncThunk(
  "restaurantMerger/getAllRestaurant",
  async ({ page, size, searchQuery }, thunkAPI) => {
    try {
      const response = await fetchGetRequest(
        endpoints.dashboard.getAllRestaurant,
        {
          params: {
            page: page,
            size: size,
            keywords: searchQuery || undefined,
          },
        }
      );
      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      // toast.error(err?.data?.message || "Failed to get Restaurants");
      console.log("get restaurant err", JSON.stringify(err, null, 4));

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const mergeRestaurant = createAsyncThunk(
  "restaurantMerger/mergeRestaurant",
  async (payload, thunkAPI) => {
    try {
      const response = await fetchPatchRequest(
        endpoints.dashboard.mergeRestaurant,
        payload
      );

      return thunkAPI.fulfillWithValue(response?.data);
    } catch (err) {
      console.log("merge Restaurant err", JSON.stringify(err, null, 4));
      toast.error(err?.response?.data?.message || "Failed to merge Restaurant");

      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const { reducer, actions } = createSlice({
  name: "restaurantMerger",
  initialState,
  reducers: {
    resetLoadingState: (state) => {
      state.isPopupLoading = false;
      state.isLoading = false;
    },
    setGetAllRestaurantIsLoadingToTrue: (state) => {
      state.isLoading = true;
    },
  },
  extraReducers: (builder) =>
    builder
      .addCase(getAllRestaurant.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRestaurant.fulfilled, (state, action) => {
        state.isLoading = false;
        state.restaurants = action?.payload?.restaurants;
      })
      .addCase(getAllRestaurant.rejected, (state) => {
        state.isLoading = false;
      })

      .addCase(mergeRestaurant.pending, (state) => {
        state.isPopupLoading = true;
      })
      .addCase(mergeRestaurant.fulfilled, (state, action) => {
        state.isPopupLoading = false;
      })
      .addCase(mergeRestaurant.rejected, (state) => {
        state.isPopupLoading = false;
      }),
});

export default reducer;
export const { resetLoadingState, setGetAllRestaurantIsLoadingToTrue } =
  actions;
