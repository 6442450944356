import React, { useCallback, useEffect, useRef, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Button from "../../../components/button";
import Input from "../../../components/input";
import "./menu.scss";
import "./menu.css";
import CreatableSelect from "react-select/creatable";
import CategoryPopup from "./categoryPopup";
import Loading from "../../../components/loading";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
  getAdminFoodCategory,
  getAdminFoodTagCategory,
  getFoodCategory,
  getFoodTagCategory,
} from "../../../../store/reducers/foodCategory";
import { getAuthMe } from "../../../../store/reducers/authMe";
import { constant } from "../../../constants";
import {
  getAdminFoodItemById,
  getFoodItemById,
} from "../../../../store/reducers/foodItemsById";
import {
  createAdminFoodItem,
  createFoodItem,
  updateAdminFoodItem,
  updateFoodItem,
} from "../../../../store/reducers/menu";
import {
  selectFoodItemByIdData,
  selectMenuItemsData,
  selectRestaurantLocationData,
} from "../../../../store/selectors/dashboardSelectors";
import { selectIsAddCategoryModalOpen } from "../../../../store/selectors/modalSelectors";
import {
  addCategoryModalHide,
  addCategoryModalOpen,
} from "../../../../store/reducers/modals";
import { userData } from "../../../../store/selectors/authSelectors";
import Select from "react-select";

const customStyles = {
  control: (provided) => ({
    ...provided,
    border: "1px solid #ced4da",
    borderRadius: "4px",
    minHeight: "38px",
    boxShadow: "none",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: "none",
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: "8px",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#ffc107",
    borderRadius: "20px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "#ffffff",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    color: "#ffc107",
    marginRight: "5px",
    marginLeft: "5px",
    marginTop: "5px",
    marginBottom: "5px",
    width: "20px",
    height: "20px",
  }),
  clearIndicator: () => ({
    display: "none",
  }),
};

const customFormatCreateLabel = () => {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className="customStyles"
    >
      <i
        style={{ fontSize: "20px", marginRight: "7px", color: "#32807F" }}
        className="bx bxs-plus-circle"
      ></i>
      Create New Category
    </div>
  );
};

const customFormatCreateLabelforTags = (inputValue) => {
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className="customStyles"
    >
      {inputValue}
    </div>
  );
};

const addModeValidationSchema = Yup.object().shape({
  menuItem: Yup.string()
    .required("Menu item name is required")
    .max(150, "Menu item name cannot exceed 150 characters")
    .trim(),
  description: Yup.string().required("Description is required").trim(),
  category: Yup.object({
    label: Yup.string().required("Category is required").trim(),
    value: Yup.string().required("Category is required").trim(),
  }),
  price: Yup.string()
    .required("Price is required")
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "Price must be a valid number with up to 2 decimal places"
    )
    .trim(),
  foodItemImageFile: Yup.mixed().nullable(),
  tag: Yup.array()
    .of(
      Yup.object({
        label: Yup.string().trim(),
        value: Yup.string().trim(),
      })
    )
    .min(1, "Select at least one tag"),
});

const editModeValidationSchema = Yup.object().shape({
  menuItem: Yup.string()
    .required("Menu item name is required")
    .max(150, "Menu item name cannot exceed 150 characters")
    .trim(),
  description: Yup.string().required("Description is required").trim(),
  category: Yup.object({
    label: Yup.string().required("Category is required").trim(),
    value: Yup.string().required("Category is required").trim(),
  }),
  price: Yup.string()
    .required("Price is required")
    .matches(
      /^\d+(\.\d{1,2})?$/,
      "Price must be a valid number with up to 2 decimal places"
    )
    .trim(),
  foodItemImageFile: Yup.mixed().nullable(),
  tag: Yup.array()
    .of(
      Yup.object({
        label: Yup.string().trim(),
        value: Yup.string().trim(),
      })
    )
    .min(1, "Select at least one tag"),
});

const AddMenuItemPopup = ({
  isOpen,
  onClose,
  isEditMode,
  getFoodsData,
  foodItemId,
}) => {
  const [restaurantIdfromFood, setRestaurantId] = useState(null);
  const [restaurantNamefromFood, setRestaurantName] = useState(null);
  const menuItemData = useSelector(selectMenuItemsData);
  const foodItemByIdData = useSelector(selectFoodItemByIdData);
  const [restaurants, setRestaurants] = useState([]);
  const [foodCategories, setFoodCategories] = useState([]);
  const isAddCategoryPopupOpen = useSelector(selectIsAddCategoryModalOpen);
  const [selectedCategoryForCreate, setSelectedCategoryForCreate] =
    useState("");
  const formikRef = useRef();
  const [imagePreview, setImagePreview] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(null);
  const dispatch = useDispatch();
  const restaurantDetailData = useSelector(selectRestaurantLocationData);
  const restaurantName = restaurantNamefromFood
    ? restaurantNamefromFood
    : restaurantDetailData?.restaurantDetails?.restaurant?.restaurantName;
  const restaurantId = restaurantIdfromFood
    ? restaurantIdfromFood
    : restaurantDetailData?.restaurantId;
  const user = useSelector(userData);
  const [foodTagCategory, setFoodTagCategory] = useState([]);

  useEffect(() => {
    const fetchTagCategoryFunction =
      user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
        ? () => dispatch(getFoodTagCategory())
        : () => dispatch(getAdminFoodTagCategory());
    fetchTagCategoryFunction().then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          setFoodTagCategory(response?.payload?.foodTagCategories);
          return;

        default:
          return;
      }
    });
  }, [dispatch, user]);

  const transformCategoryItems = (tagCategory) => {
    return tagCategory.foodTagCategoryItems.map((item) => ({
      value: item.foodTagCategoryItemName,
      label: item.foodTagCategoryItemName,
    }));
  };

  const chunkArray = (array, size) => {
    const chunked = [];
    for (let i = 0; i < array.length; i += size) {
      chunked.push(array.slice(i, i + size));
    }
    return chunked;
  };

  const flattenedCategories = foodTagCategory.flat(); // to create a single-level array from the nested structure
  const chunkedCategories = chunkArray(flattenedCategories, 2); //used to group the categories into pairs

  useEffect(() => {
    if (isEditMode && foodItemId) {
      const fetchFoodItemByIdFunction =
        user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
          ? () => dispatch(getFoodItemById(foodItemId))
          : () => dispatch(getAdminFoodItemById({ foodId: foodItemId }));
      fetchFoodItemByIdFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            const { food } = response?.payload || {};
            if (food.length) {
              const restaurantFromFood = food?.[0]?.restaurant;
              setRestaurantId(restaurantFromFood?.restaurantId);
              setRestaurantName(restaurantFromFood?.restaurantName);

              formikRef?.current?.setFieldValue("restaurantname", {
                label: food?.[0]?.restaurant?.restaurantName,
                value: food?.[0]?.restaurant?.restaurantId,
              });
              formikRef?.current?.setFieldValue(
                "menuItem",
                food?.[0]?.foodItemName
              );
              formikRef?.current?.setFieldValue(
                "description",
                food?.[0]?.description
              );
              formikRef?.current?.setFieldValue("category", {
                label: food?.[0]?.foodCategory?.categoryName,
                value: food?.[0]?.foodCategory?.categoryId,
              });
              formikRef?.current?.setFieldValue("price", food?.[0]?.price);
              formikRef?.current?.setFieldValue(
                "tag",
                food?.[0]?.tags?.map((tag) => ({
                  label: tag,
                  value: tag,
                }))
              );
              formikRef?.current?.setFieldValue(
                "foodItemImageFile",
                food?.[0]?.FoodItemImageUrl
              );
              const additionalTags = food?.[0]?.extraAttributes || {};
              Object.keys(additionalTags).forEach((tagCategoryName) => {
                const tagValues = additionalTags[tagCategoryName];
                chunkedCategories.forEach((pair, pairIndex) => {
                  pair.forEach((category, index) => {
                    if (category.foodTagCategoryName === tagCategoryName) {
                      const fieldName = `additionalTags-${pairIndex}-${index}`;
                      formikRef?.current?.setFieldValue(
                        fieldName,
                        (tagValues || [])?.map((tag) => ({
                          label: tag,
                          value: tag,
                        }))
                      );
                    }
                  });
                });
              });
            }
            return;

          default:
            return;
        }
      });
    }
  }, [dispatch, isEditMode, foodItemId]);

  const getFoodCategoryData = useCallback(() => {
    const isAdmin = !(
      user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
    );
    if (isAdmin && restaurantId) {
      const fetchCategoryFunction = isAdmin
        ? () => dispatch(getAdminFoodCategory(restaurantId))
        : () => dispatch(getFoodCategory());
      fetchCategoryFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            setFoodCategories(
              response?.payload?.categories
                ?.filter((item) => item?.categoryId !== -1)
                ?.map((item) => ({
                  value: item?.categoryId,
                  label: item?.categoryName,
                }))
            );

            return;

          default:
            return;
        }
      });
    } else if (!isAdmin) {
      dispatch(getFoodCategory()).then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            setFoodCategories(
              response?.payload?.categories?.map((item) => {
                return {
                  value: item?.categoryId,
                  label: item?.categoryName,
                };
              })
            );
            return;

          default:
            return;
        }
      });
    }
  }, [dispatch, restaurantId, user.userSettings?.isSuperAdmin, user.userType]);

  useEffect(() => {
    getFoodCategoryData();
  }, [
    dispatch,
    getFoodCategoryData,
    isAddCategoryPopupOpen,
    restaurantId,
    isOpen,
  ]);

  const closeAddCategoryPopup = () => {
    if (user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin) {
      dispatch(getFoodCategory());
    } else {
      dispatch(getAdminFoodCategory(restaurantId));
    }
    dispatch(addCategoryModalHide());
  };

  const addFood = async (values, setSubmitting) => {
    const formData = new FormData();
    formData.append("foodCategoryId", values?.category?.value);
    formData.append("foodItemName", values?.menuItem?.trim());
    formData.append("description", values?.description?.trim());
    formData.append("price", String(values?.price)?.trim());

    const additionalTags = {};
    Object.keys(values).forEach((key) => {
      if (key.startsWith("additionalTags-")) {
        const [_, pairIndex, index] = key.split("-");
        const tagCategoryName =
          chunkedCategories[pairIndex][index].foodTagCategoryName;
        const tagValues = values[key].map((item) => item.label);
        additionalTags[tagCategoryName] = tagValues;
      }
    });

    formData.append("additionalTags", JSON.stringify(additionalTags));

    if (values?.foodItemImageFile instanceof File) {
      formData.append("foodItemImageFile", values.foodItemImageFile);
    }
    values.tag.forEach((item, index) => {
      formData.append("tags", item.label);
    });
    if (restaurantId) {
      formData.append("restaurantId", restaurantId);
    }

    if (isEditMode && foodItemId) {
      const updateFoodItemFunction =
        user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
          ? () => dispatch(updateFoodItem({ formData, foodItemId }))
          : () =>
              dispatch(updateAdminFoodItem({ formData, foodId: foodItemId }));
      updateFoodItemFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            toast.success(
              response?.payload?.message || "Food Item updated successfully"
            );
            getFoodsData();
            setImagePreview(null);
            onClose();
            return;

          default:
            return;
        }
      });
    } else {
      const createFoodItemFunction =
        user.userType === "Restaurant" && !user.userSettings?.isSuperAdmin
          ? () => dispatch(createFoodItem(formData))
          : () => dispatch(createAdminFoodItem({ formData }));
      createFoodItemFunction().then((response) => {
        switch (response?.meta?.requestStatus) {
          case constant.thunkStatus.FULFILLED:
            toast.success(
              response?.payload?.message || "Food Item created successfully"
            );
            getFoodsData();
            setImagePreview(null);
            onClose();
            return;

          default:
            return;
        }
      });
    }
  };

  useEffect(() => {
    if (!isEditMode) {
      setImagePreview(null);
      setUploadedImage(null);
    } else if (!uploadedImage) {
      setImagePreview(null);
    } else if (foodItemId) {
      dispatch(getFoodItemById(foodItemId));
    }
  }, [isEditMode, foodItemId, uploadedImage]);

  const clearImagePreview = () => {
    setImagePreview(null);
    formikRef?.current?.setFieldValue("foodItemImageFile", null);
  };

  const fileInputRef = useRef(null);

  const toggleImagePreview = () => {
    if (imagePreview) {
      clearImagePreview();
    } else {
      fileInputRef.current.click();
    }
  };

  const SUPPORTED_FILE_TYPES = {
    "image/png": "png",
    "image/jpeg": "jpg",
    "image/gif": "gif",
    "image/bmp": "bmp",
    "image/webp": "webp",
  };

  useEffect(() => {
    dispatch(getAuthMe()).then((response) => {
      switch (response?.meta?.requestStatus) {
        case constant.thunkStatus.FULFILLED:
          const data = response?.payload?.user;
          if (data?.restaurant) {
            const restaurant = {
              label: data?.restaurant?.restaurantName || "",
              value: data?.restaurant?.restaurantId || "",
            };
            setRestaurants(restaurant);
          }
          return;

        default:
          return;
      }
    });
  }, [dispatch]);

  return (
    isOpen && (
      <div className="popup">
        <div className="popup-content longWith addMenuPop">
          <h2>{isEditMode ? "Edit Menu Item" : "Add a Menu Item"}</h2>
          <div id="style-4" className="scrollbar">
            <div className="force-overflow">
              <div>
                {foodItemByIdData?.isLoading && (
                  <div className="tableLoader">
                    <div
                      style={{ borderRadius: "30px", top: "0" }}
                      className="spinnerLoad"
                    >
                      <Loading></Loading>
                    </div>
                  </div>
                )}
                <Formik
                  innerRef={formikRef}
                  initialValues={{
                    menuItem: "",
                    description: "",
                    category: { label: "", value: "" },
                    price: "",
                    tag: [],
                    foodItemImageFile: "",
                    additionalTags: {},
                  }}
                  validationSchema={
                    isEditMode
                      ? editModeValidationSchema
                      : addModeValidationSchema
                  }
                  onSubmit={async (values, { setSubmitting }) => {
                    addFood(values, setSubmitting);
                  }}
                >
                  {({
                    isSubmitting,
                    isValid,
                    setFieldValue,
                    values,
                    handleBlur,
                    handleChange,
                  }) => {
                    return (
                      <Form>
                        <div className="flexRow">
                          <div className="formCol">
                            <div className="form-group">
                              <label htmlFor="restaurantname">Restaurant</label>
                              <Field
                                type="text"
                                name="restaurantname"
                                id="restaurantname"
                                placeholder="Restaurant Name"
                                component={Input}
                                readOnly
                                disabled
                                value={
                                  restaurants?.label || restaurantName || ""
                                }
                              />
                              <div className="error-wrapper">
                                <ErrorMessage name="restaurantname.label" />
                              </div>
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form-group">
                              <label htmlFor="menuItem">Menu Item Name</label>
                              <Field
                                type="text"
                                name="menuItem"
                                id="menuItem"
                                placeholder="Menu Item Name"
                                component={Input}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="flexRow">
                          <div className="formCol fullWidth">
                            <div className="form-group">
                              <label htmlFor="description">Description</label>
                              <div className="dFlex">
                                <textarea
                                  placeholder="Add your description here..."
                                  value={values.description}
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="description"
                                ></textarea>
                              </div>
                              <div className="error-wrapper">
                                <ErrorMessage name="description" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flexRow">
                          <div className="formCol">
                            <div className="form-group">
                              <label htmlFor="category">Category</label>
                              <CreatableSelect
                                placeholder="Select"
                                value={values.category}
                                isSearchable={true}
                                name="category"
                                options={foodCategories}
                                styles={customStyles}
                                className="catselectDropcustom"
                                formatCreateLabel={customFormatCreateLabel}
                                onChange={(e) => {
                                  if (e.__isNew__) {
                                    dispatch(addCategoryModalOpen());
                                    setSelectedCategoryForCreate(e.label);
                                  } else {
                                    setFieldValue("category", e);
                                  }
                                }}
                                onBlur={handleBlur}
                              />
                              <div className="error-wrapper">
                                <ErrorMessage name="category.label" />
                              </div>
                            </div>
                          </div>
                          <div className="formCol">
                            <div className="form-group">
                              <label htmlFor="price">Price</label>
                              <Field
                                type="text"
                                name="price"
                                id="price"
                                placeholder="Item Price"
                                component={Input}
                              />
                            </div>
                          </div>
                        </div>
                        <div>
                          {chunkedCategories.length > 0 &&
                            chunkedCategories.map((pair, pairIndex) => (
                              <div className="flexRow" key={pairIndex}>
                                {pair.map((additionalTags, index) => {
                                  const transformedCategoryItems =
                                    transformCategoryItems(additionalTags);
                                  const fieldName = `additionalTags-${pairIndex}-${index}`;

                                  return (
                                    <div className="formCol" key={index}>
                                      <div className="form-group">
                                        <label htmlFor={fieldName}>
                                          {additionalTags.foodTagCategoryName}
                                        </label>
                                        <Select
                                          options={transformedCategoryItems}
                                          styles={customStyles}
                                          isMulti={true}
                                          placeholder={`Select ${additionalTags.foodTagCategoryName}`}
                                          value={values[fieldName] || []}
                                          isSearchable={true}
                                          name={fieldName}
                                          className="catselectDropcustom"
                                          onChange={(selectedOptions) => {
                                            setFieldValue(
                                              fieldName,
                                              (selectedOptions || []).map(
                                                (item) => ({
                                                  value: item.value,
                                                  label: item.label,
                                                })
                                              )
                                            );
                                          }}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            ))}
                        </div>
                        <div className="flexRow">
                          <div className="formCol colOne">
                            <div className="form-group">
                              <label htmlFor="tag">Tags</label>
                              <CreatableSelect
                                styles={customStyles}
                                isMulti={true}
                                placeholder="Start typing..."
                                value={values.tag}
                                isSearchable={true}
                                name="tag"
                                formatCreateLabel={
                                  customFormatCreateLabelforTags
                                }
                                onChange={(e) => {
                                  console.log(e);
                                  setFieldValue(
                                    "tag",
                                    e.map((item) => {
                                      return {
                                        value: item.value,
                                        label: item.label,
                                      };
                                    })
                                  );
                                }}
                                onBlur={handleBlur}
                              />
                              <div className="error-wrapper">
                                <ErrorMessage name="tag" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flexRow">
                          <div className="formCol colOne">
                            <div className="form-group">
                              <label htmlFor="tag">Add Photo</label>
                              {!values?.foodItemImageFile ? (
                                <ul className="addImageList">
                                  <li className="addSec">
                                    <label
                                      className="control-label file_up"
                                      htmlFor="foodItemImageFile"
                                    >
                                      <input
                                        type="file"
                                        id="foodItemImageFile"
                                        className="optional inputfile"
                                        name="foodItemImageFile"
                                        onChange={(e) => {
                                          if (
                                            e?.target?.files[0] &&
                                            SUPPORTED_FILE_TYPES[
                                              e?.target?.files[0]?.type
                                            ]
                                          ) {
                                            formikRef?.current?.setFieldValue(
                                              "foodItemImageFile",
                                              e.target.files[0]
                                            );
                                          } else {
                                            toast.error(
                                              "Unsupported file type. Please upload a file of type: png, jpg, gif, bmp, webp."
                                            );
                                          }
                                        }}
                                        style={{ display: "none" }}
                                      />
                                      <i className="bx bx-plus"></i>
                                    </label>
                                  </li>
                                </ul>
                              ) : (
                                <ul className="addImageList">
                                  <li>
                                    <img
                                      src={
                                        values?.foodItemImageFile instanceof
                                        File
                                          ? URL.createObjectURL(
                                              values?.foodItemImageFile
                                            )
                                          : values?.foodItemImageFile
                                      }
                                      alt="Preview"
                                      className="previewImage"
                                      // onClick={toggleImagePreview}
                                    />
                                    <div
                                      className="removeIcon"
                                      onClick={clearImagePreview}
                                    >
                                      <i className="McircleIcon">
                                        <span
                                          style={{
                                            color: "white",
                                            fontSize: "21px",
                                            position: "relative",
                                            marginTop: "-4px",
                                            marginLeft: "-2px",
                                          }}
                                        >
                                          −
                                        </span>
                                      </i>
                                    </div>
                                  </li>
                                  <li className="addSec">
                                    <label
                                      className="control-label file_up"
                                      htmlFor="foodItemImageFile"
                                    >
                                      <input
                                        type="file"
                                        id="foodItemImageFile"
                                        className="optional inputfile"
                                        name="foodItemImageFile"
                                        onChange={(e) => {
                                          if (
                                            e?.target?.files[0] &&
                                            SUPPORTED_FILE_TYPES[
                                              e?.target?.files[0]?.type
                                            ]
                                          ) {
                                            formikRef?.current?.setFieldValue(
                                              "foodItemImageFile",
                                              e.target.files[0]
                                            );
                                          } else {
                                            toast.error(
                                              "Unsupported file type. Please upload a file of type: png, jpg, gif, bmp, webp."
                                            );
                                          }
                                        }}
                                        style={{ display: "none" }}
                                      />
                                      <i className="bx bx-plus"></i>
                                    </label>
                                  </li>
                                </ul>
                              )}

                              <div className="error-wrapper">
                                <ErrorMessage name="foodItemImageFile" />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flxendrightBtn">
                          <Button
                            type="submit"
                            className="cancleBtn"
                            onClick={onClose}
                          >
                            Cancel
                          </Button>
                          <Button
                            type="submit"
                            disabled={isSubmitting || !isValid}
                            className="doneBtnmenu"
                          >
                            {menuItemData?.isPopupLoading && (
                              <Loading></Loading>
                            )}
                            {isSubmitting
                              ? "Submitting..."
                              : isEditMode
                              ? "Done"
                              : "Save"}
                          </Button>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </div>
        </div>
        <CategoryPopup
          isOpen={isAddCategoryPopupOpen}
          onClose={closeAddCategoryPopup}
          selectedCategoryForCreate={selectedCategoryForCreate}
          setSelectedCategoryForCreate={() => {
            setSelectedCategoryForCreate("");
          }}
          getFoodsData={getFoodsData}
          restaurantIdFromFood={restaurantId}
        />
      </div>
    )
  );
};

export default AddMenuItemPopup;
